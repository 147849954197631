<template>
	<v-card
		max-width="340"
		class="my-2 overflow-hidden mx-auto"
		flat
		rounded="xl"
	>
		<div
			class="fb-group"
			data-href="https://www.facebook.com/groups/sachchaikendranepal"
			data-width="340"
			data-show-social-context="true"
			data-show-metadata="false"
		>
			<blockquote
				cite="https://www.facebook.com/groups/sachchaikendranepal"
				class="fb-xfbml-parse-ignore pa-4"
			>
				Sachchai Kendra Nepal
			</blockquote>
		</div>
	</v-card>
</template>

<script>
export default {
	name: "FacebookGroup"
}
</script>

<style scoped lang="scss">
.fb-group {
	width: 100%;
}

</style>
